import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useState, useCallback } from 'react';

function useHome(path = 'home') {

  const [ isHome, setIsHome ] = useState(false);
  const pathname = usePathname();


  useEffect(() => {
    const pathArr = pathname.split('/').filter(item => item);
    if (pathArr.includes(path)) {
      setIsHome(true);
    } else {
      setIsHome(false);
    }

  }, [pathname, path]);

  return {
    isHome,
  };

}


function useRouterGo() {

  const pathname = usePathname();
  const router = useRouter();
  const goBookMagazine = useCallback(() => {
    const lng = pathname.split('/').filter(item => item && item !== '/')[0];
    router.push('/' + lng + '/bookmagazine/list/1/');
  }, [pathname, router]);

  const goCollection = useCallback(() => {
    const lng = pathname.split('/').filter(item => item && item !== '/')[0];
    router.push('/' + lng + '/collection/');
  }, [pathname, router]);

  const goPrefix = useCallback((path: string) => {
    const lng = pathname.split('/').filter(item => item && item !== '/')[0];
    router.push('/' + lng + path );
  }, [pathname, router]);



  return {
    goBookMagazine,
    goCollection,
    goPrefix,
  };
}


export {
  useHome,
  useRouterGo,
};
