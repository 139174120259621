
'use client';
import clsx from 'clsx';
// import { useClientTranslation } from '@/hook/translate';
import { useActivePath } from '@/hook';
import { useCallback } from 'react';
import {
  useParams, 
  // usePathname,
} from 'next/navigation';
import { useRouter } from 'next/navigation';
import { MindfulAudioImg } from '@/config/img/mindfulAudio';
import { useHome } from '@/hook/routerPath';
import { dealLng } from '@/utils';
import LanguageChange from './LanguageChange';
import { Image } from '@nextui-org/react';

export default function BigHeader() {
  // const { t } = useClientTranslation();
  const activePath = useActivePath(['bookmagazine', 'collection', 'pdf']);
  // const pathname = usePathname();
  const params = useParams();
  const router = useRouter();
  const { isHome } = useHome();
  // const goBookMagazine = useCallback(() => {
  //   const lng = pathname.split('/').filter(item => item && item !== '/')[0];
  //   router.push('/' + lng + '/bookmagazine/list/1/');
  // }, [pathname, router]);

  // const goCollection = useCallback(() => {
  //   const lng = pathname.split('/').filter(item => item && item !== '/')[0];
  //   router.push('/' + lng + '/collection/');
  // }, [pathname, router]);

  const goPDF = useCallback(() => {
    let lng = dealLng(params.lng as string || '');
    console.log(lng);
    router.push(lng + '/pdf/1/');
  }, [params, router]);

  const goHome = useCallback(() => {
    let lng = dealLng(params.lng as string || '');
    router.push(lng + '/home/all/all/1/');
  }, [params, router]);

  return (
    <div className={ clsx('h-20 flex items-center px-[150px] ', isHome ? 'bg-[#E7FBB0]' : 'bg-[#FAFAFA]') }>
      <Image src={ MindfulAudioImg.Logo } className='cursor-pointer' alt='logo' width={170} height={35} onClick={ goHome } />
      <div className='flex-1 flex justify-end h-full items-center'>
        {/* <p 
          className={
            clsx(' mr-10 text-20-27 font-bold h-full flex items-center',
              activePath[0] ? 'text-[#3AD1FF border-b-5 border-[#CDF564]' : 'text-black',
            )
          }
        >
          <span className='cursor-pointer'  onClick={goBookMagazine}>{ t('bookMagazine', 'listenBriefs') }</span>
        </p> */}
        {/* <p className={
          clsx('mr-10 text-20-27 font-bold h-full flex items-center',
            activePath[1] ? 'text-[#3AD1FF]  border-b-5 border-[#CDF564]' : 'text-black',
          )
        }>
          <span className='cursor-pointer'  onClick={goCollection} >{ t('collection', 'listenBriefs') }</span>
        </p> */}
        <p 
          className={
            clsx(' mr-10 cursor-pointer text-20-27 font-bold h-full flex items-center',
              activePath[2] ? 'text-[#3AD1FF]  border-b-5 border-[#CDF564]' : 'text-black',
            )
          }
          onClick={goPDF}
        >PDF</p>
        <LanguageChange />
      </div>
    </div>
  );
}
